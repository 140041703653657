<script setup lang="ts">
type Header = {
  title: string
  key: string
  sortable: boolean
  class?: string
}
type SortColumn = { key: string; direction: "asc" | "desc" }
defineProps<{
  headers: Header[]
}>()
const sortColumn = defineModel<SortColumn>()
</script>
<template>
  <tr>
    <TableHeaderCell
      v-for="header in headers"
      :key="header.key"
      v-model="sortColumn"
      :class="header.class"
      :sort-key="header.key"
      :sortable="header.sortable"
      >{{ header.title }}</TableHeaderCell
    >
  </tr>
</template>
